import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import Technologies from '@solid-ui-blocks/Technologies/Block01'
import BuildBrand from '@solid-ui-blocks/FeaturesWithPhoto/Block04'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import WhyChooseUs from '@solid-ui-blocks/Features/Block01'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import FeatureThree from '@solid-ui-blocks/Features2/Block02'
import FeatureFour from '@solid-ui-blocks/Features3/Block02'
import FeatureFive from '@solid-ui-blocks/Feature4/Block02'
import FeatureSix from '@solid-ui-blocks/Feature5/Block02'

import Stats from '@solid-ui-blocks/Stats/Block01'
import Strategies from '@solid-ui-blocks/Features/Block06'
import Download from '@solid-ui-blocks/CallToAction/Block02'
import Testimonials from '@solid-ui-blocks/Testimonials/Block02'
import GetStarted from '@solid-ui-blocks/CallToAction/Block01'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Team from '@solid-ui-blocks/Team/Block01'

import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import HowItWorks from '@solid-ui-blocks/FeaturesWithPhoto/Block04'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      {/* <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
        <Divider space='5' />
      </Container> */}
      <Divider space='5' />
      <HowItWorks content={content['how-it-works']} />
      {/* <Divider space='5' /> */}
      {/* <Technologies  content={content['Technologies']} /> */}
      {/* <Container variant='full' sx={styles.buildBrandContainer}> */}
      {/* <BuildBrand content={content['build-brand']} /> */}
      {/* </Container> */}
      {/* <Divider space='5' /> */}
      {/* <FeatureOne content={content['feature-one']} /> */}
      {/* <Divider space='5' /> */}
      {/* <WhyChooseUs content={content['why-choose-us']} /> */}
      {/* <Divider space='5' /> */}
      {/* <Blog content={content['latest-blogs']} /> */}
      {/* <Divider space='5' /> */}
      {/* <Companies content={content['companies']} /> */}
      {/* <Divider space='5' /> */}
      <FeatureTwo content={content['feature-two']} reverse />
      <Divider space='5' />
      <FeatureThree content={content['feature-three']} reverse />
      <Divider space='5' />
      <FeatureFour content={content['feature-four']} reverse />
      {/* <Stats content={content['stats']} /> */}
      <Divider space='4' />
      <FeatureFive content={content['feature-five'] }reverse/>
      {/* <Strategies content={content['strategies']} /> */}
      <Divider space='4' />
      <FeatureSix content={content['feature-six'] }reverse/>


      {/* <Download content={content['download']} /> */}
      {/* <Divider space='5' />
      <Divider space='5' />
      <Testimonials content={content['testimonials']} />
      <Divider space='5' />
      <Divider space='5' /> */}
      {/* <Container sx={styles.getStartedContainer}>
        <GetStarted content={content['get-started']} />
      </Container> */}
      {/* <Divider space='5' /> */}
      {/* <Team content={content['team']} /> */}
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query sectionpageSiteBlockContent   {
    allBlockContent(
      filter: { page: { in: ["site/services-section", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
